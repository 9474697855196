<template>
  <div ref="ingridForm"></div>
</template>

<script setup lang="ts">
import { replaceScriptNode } from '~/util/ingrid';
import { PurchaseType } from '~/models/api-types';
import * as Sentry from '@sentry/vue'
const { apiGet } = useApiFetch();


const props = defineProps<{
  snippet: string,
  purchaseType?: string
  subscriptionId?: number,
}>();

const ingridForm = ref();

onMounted(() => {
  initDeliveryCheckout();
});

const initDeliveryCheckout = async() => {
  const el = ingridForm.value;
  el.innerHTML = props.snippet;
  replaceScriptNode(document.getElementById('shipwallet-container'));

  setupGeneralJSListeners();
};

const setupGeneralJSListeners = () => {
  if (typeof (window._sw) != undefined) {
    window._sw(async (api) => {
      api.on('data_changed', async (data, meta) => {
        if ((meta.price_changed || meta.shipping_method_changed || meta.delivery_type_change) && !meta.initial_load) {
          api.suspend();
          await updateIngrid();
          api.resume();
        }
      });
    });
  }
};


const updateIngrid = async() => {
  let updateUrl = `ingrid/update/?purchaseType=${props.purchaseType ? props.purchaseType : PurchaseType.Standard}`;
  if (props.subscriptionId && props.subscriptionId > 0) {
    updateUrl = updateUrl + `&subscriptionId=${props.subscriptionId}`;
  }
  await apiGet(updateUrl);
};
</script>
