const isScriptNode = (node) => {
  return node.tagName === 'SCRIPT';
};

const isExternalScript = (node) => {
  return !!node.src && node.src !== '';
};

const cloneScriptNode = (node) => {
  const script = document.createElement('script');
  script.text = node.innerHTML;
  for (let i = node.attributes.length - 1; i >= 0; i--) {
    script.setAttribute(node.attributes[i].name, node.attributes[i].value);
  }
  return script;
};

export const replaceScriptNode = (node) => {
  if (isScriptNode(node) && !isExternalScript(node)) {
    if (node.parentNode) {
      node.parentNode.replaceChild(cloneScriptNode(node), node);
    }
  } else {
    let i = 0;
    const children = node.childNodes;
    while (i < children.length) {
      replaceScriptNode(children[i++]);
    }
  }
  return node;
};
